import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Form, Button } from 'react-bootstrap';
import { login } from './api/api';
import { useAuth } from './contexts/AuthContext';
import { toast } from 'react-toastify';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { login: authLogin } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await login(email, password);
      
      if (response.status === 200 && response.data.accessToken) {
        localStorage.setItem('token', response.data.accessToken);
        toast.success('Login successful!');
        authLogin(response.data);
        navigate('/', { replace: true });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error)
      toast.error('Error');
    }
  };

  const onForgotPasswordClick = () => {}
  const onRegisterClick = () => {
    navigate('/register')
  }

  return (
    <Card className="mx-auto mt-5" style={{ maxWidth: '400px' }}>
      <Card.Body>
        <h2 className="text-center mb-4">Login</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control 
              type="email" 
              placeholder="Email" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control 
              type="password" 
              placeholder="Password" 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="warning" type="submit" className="w-100 mb-3 bg-primary text-white">
            Login
          </Button>
        </Form>
        <div className="text-center">
          <Button variant="link" onClick={onForgotPasswordClick}>Forgot password?</Button>
        </div>
        <div className="text-center mt-3">
          Not registered? <Button variant="link" onClick={onRegisterClick}>Create an account</Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Login;