import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useLanguage } from '../contexts/LanguageContext';
import JobListingDetail from './JobListingDetail';
import { getJobs } from '../api/api';
import { toast } from 'react-toastify';
import { Job } from '../types';


const JobListingsDashboard: React.FC = () => {
  const [jobListings, setJobListings] = useState<Job[]>([]);
  const [selectedListing, setSelectedListing] = useState<Job | null>(null);
  const { t } = useLanguage();

  useEffect(() => {
    const fetchJobListings = async () => {
      try {
        const response = await getJobs();
        setJobListings(response.data);
      } catch (error) {
        toast.error('Error fetching job listings:')
        console.error('Error fetching job listings:', error);
      }
    };

    fetchJobListings();
  }, []);

  return (
    <div className="container mt-4">
      <h1>{t('jobListings')}</h1>
      <Row>
        <Col md={4}>
          {jobListings.map((listing) => (
            <Card key={listing.id} className="mb-3" onClick={() => setSelectedListing(listing)}>
              <Card.Body>
                <Card.Title>{listing.title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{listing.company}</Card.Subtitle>
                <Card.Text>{listing.location}</Card.Text>
              </Card.Body>
            </Card>
          ))}
        </Col>
        <Col md={8}>
          {selectedListing && <JobListingDetail {...selectedListing} />}
        </Col>
      </Row>
    </div>
  );
};

export default JobListingsDashboard;