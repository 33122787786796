import React, { useState } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { register } from './api/api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Register: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await register(email, password);
      if (response.status === 201) {
        toast.success('Register successfull');
        navigate('/login')
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Error');
    }
  };

  const onLoginClick = () => {
    navigate('/login')
  }

  return (
    <Card className="mx-auto mt-5" style={{ maxWidth: '400px' }}>
      <Card.Body>
        <h2 className="text-center mb-4">Create an Account</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control 
              type="email" 
              placeholder="Email" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control 
              type="password" 
              placeholder="Password" 
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="warning" type="submit" className="w-100 mb-3 bg-primary text-white">
            Register
          </Button>
        </Form>
        <div className="text-center mt-3">
          Already have an account? <Button variant="link" onClick={onLoginClick}>Login</Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Register;