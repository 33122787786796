import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="bg-light mt-5 py-3">
      <Container>
        <Row className="align-items-center">
          <Col md={4} className="text-center text-md-start mb-3 mb-md-0">
            <span>&copy; 2024 InfoPlus. All rights reserved.</span>
          </Col>
          <Col md={4} className="text-center mb-3 mb-md-0">
            <Link to="/about" className="text-decoration-none text-dark me-3">About</Link>
            <Link to="/contact" className="text-decoration-none text-dark me-3">Contact</Link>
            <Link to="/privacy" className="text-decoration-none text-dark">Privacy Policy</Link>
          </Col>
          <Col md={4} className="text-center text-md-end">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-dark me-3">
              <FaFacebook size={20} />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-dark me-3">
              <FaTwitter size={20} />
            </a>
            <a href="https://www.linkedin.com/in/uoncul/" target="_blank" rel="noopener noreferrer" className="text-dark">
              <FaLinkedin size={20} />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;