import React from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useLanguage } from '../contexts/LanguageContext';
import { createJobOffer } from '../api/api';
import { toast } from 'react-toastify';

const CreateJobListing: React.FC = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { t } = useLanguage();

  const onSubmit = async (data: any) => {
    try {
      const response = await createJobOffer(data);
      if (response.status === 201) {
        toast.success('Job Created');
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Failed to job profile:', error);
      toast.error('Failed to create job. Please try again.');
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">{t('createJobListing')}</h2>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('title')}</Form.Label>
                  <Form.Control 
                    {...register('title', { required: t('titleRequired') })} 
                    isInvalid={!!errors.title}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t('company')}</Form.Label>
                  <Form.Control 
                    {...register('company', { required: t('companyRequired') })} 
                    isInvalid={!!errors.company}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t('location')}</Form.Label>
                  <Form.Control 
                    {...register('location', { required: t('locationRequired') })} 
                    isInvalid={!!errors.location}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t('description')}</Form.Label>
                  <Form.Control 
                    as="textarea" 
                    rows={5} 
                    {...register('description', { required: t('descriptionRequired') })} 
                    isInvalid={!!errors.description}
                  />
                </Form.Group>

                <div className="d-grid gap-2">
                  <Button variant="primary" type="submit" size="lg">
                    {t('createJobListing')}
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateJobListing;