import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container, Image, Button } from 'react-bootstrap';
import { useAuth } from './contexts/AuthContext';
import { useLanguage } from './contexts/LanguageContext';

export interface HeaderProps {
  isProfileCreated?: boolean;
  onLogout: () => void;
  isAdmin: boolean;
}

const Header: React.FC<HeaderProps> = ({ onLogout, isAdmin }) => {
  const { isProfileCreated } = useAuth();
  const { language, setLanguage, t } = useLanguage();

  const toggleLanguage = () => {
    setLanguage(language === 'en' ? 'tr' : 'en');
  };

  return (
    <Navbar bg="light" expand="lg" className="mb-4 shadow-sm" style={{ backgroundColor: '#f8f9fa' }}>
      <Container fluid>
      <Image
            src="/logo.png" 
            width="110"
            height="40"
            className="d-inline-block align-top me-2"
            alt="Logo"
          />
        <Navbar.Brand as={Link} to="/">Home</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {isAdmin && (
              <Nav.Link as={Link} to="/admin/approval" className="text-dark">
                Admin
              </Nav.Link>
            )}
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/profile" className="text-dark">
              {isProfileCreated ? t('update_profile') : t('create_profile')}
            </Nav.Link>
            <Nav.Link as={Link} to="/job-listings">{t('jobListings')}</Nav.Link>
            <Nav.Link as={Link} to="/create-job-listing">{t('createJobListing')}</Nav.Link>
          </Nav>
          <Button onClick={toggleLanguage} variant="outline-primary" className="me-2">
            {language === 'en' ? 'EN' : 'TR'}
          </Button>
          <Nav>
            <Nav.Link onClick={onLogout} className="text-dark">{t('logout')}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;