import React, { useState } from 'react';
import { Card, Table, Nav } from 'react-bootstrap';
import { Profile } from './types';
import { LanguageCode } from './translations';
import { useLanguage } from './contexts/LanguageContext';

interface ProfileDetailProps {
    profile: Profile;
    language: LanguageCode;
    isAdmin: boolean;
  }

const ProfileDetail: React.FC<ProfileDetailProps> = ({ profile, language, isAdmin }) => {
  const [activeTab, setActiveTab] = useState('about');
  const { t } = useLanguage();

  const renderAdminInfo = () => (
    <Table striped hover className="mt-3" style={{ fontWeight: 'normal' }}>
      <tbody>
        <tr>
          <th>{t('availability')}</th>
          <td>{profile.availability}</td>
        </tr>
        <tr>
          <th>{t('phoneNumber')}</th>
          <td>{profile.phoneNumber}</td>
        </tr>
        <tr>
          <th>Linkedin</th>
          <td>{profile.linkedin}</td>
        </tr>
      </tbody>
    </Table>
  );

  const renderAbout = () => (
    <>
      <h3>{t('summary')}</h3>
      <p>{profile.aboutMe}</p>
      {isAdmin && renderAdminInfo()}
      {renderSkills()}
      {renderExperiences()}
      {renderLanguages()}
      {renderEducation()}
    </>
  );

  const renderExperiences = () => { 
    if (!profile.experiences || profile.experiences.length === 0) {
      return null;
    }
    return (
      <>
      <h3>{t('experiences')}</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>{t('company')}</th>
            <th>{t('title')}</th>
            <th>{t('duration')}</th>
          </tr>
        </thead>
        <tbody>
          {profile.experiences.map((exp, index) => (
            <React.Fragment key={index}>
              <tr>
                <td>{exp.company}</td>
                <td>{exp.title}</td>
                <td>{`${exp.startDate.substring(0,7)} - ${exp.endDate?.substring(0,7) || t('present')}`}</td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <p>{exp.description}</p>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </>
  )};

  const renderEducation = () => {
    if (!profile.education || profile.education.length === 0) {
      return null;
    }
    return (
      <>
      <h3>{t('education')}</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>{t('school')}</th>
            <th>{t('department')}</th>
            <th>{t('duration')}</th>
          </tr>
        </thead>
        <tbody>
          {profile.education.map((edu, index) => (
            <tr key={index}>
              <td>{edu.name}</td>
              <td>{edu.department}</td>
              <td>{`${edu.startDate.substring(0,7)} - ${edu.endDate?.substring(0,7) || 'present'}`}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )};

  const renderLanguages = () => {
    if (!profile.language || profile.language.length === 0) {
      return null;
    }
    return (
    <>
    <h3>{t('languages')}</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>{t('language')}</th> 
            <th>{t('level')}</th>
          </tr>
        </thead>
        <tbody>
          {profile.language.map((lang:any, index:any) => (
            <tr key={index}>
              <td>{lang.name}</td>
              <td>{lang.level}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )};

  const renderSkills = () => {
    if (!profile.technologies || profile.technologies.length === 0) {
      return null;
    }
    
    return (
      <>
        <h3>{t('technologies')}</h3>
        <div className="skills-container" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          {profile.technologies.map((skill, index) => (
            <span 
              key={index}
              style={{
                backgroundColor: '#f0f0f0',
                padding: '10px 10px',
                borderRadius: '15px',
                marginBottom: '10px',
                fontSize: '16px',
                display: 'inline-block'
              }}
            >
              {skill.name}
            </span>
          ))}
        </div>
      </>
    );
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>{profile.title}</Card.Title>
        {isAdmin && (
                    <Card.Subtitle className="mb-2 text-muted">
                    {`${profile.name} ${profile.surname}`}
                  </Card.Subtitle>
        )}
        <Nav variant="tabs" className="mb-3" activeKey={activeTab} onSelect={(k) => setActiveTab(k || 'about')}>
          <Nav.Item>
            <Nav.Link eventKey="about">{t('about')}</Nav.Link>
          </Nav.Item>
          {profile.technologies && profile.technologies.length > 0 && (
          <Nav.Item>
            <Nav.Link eventKey="skills">{t('skills')}</Nav.Link>
          </Nav.Item>
        )}
          <Nav.Item>
            <Nav.Link eventKey="summary">{t('summary')}</Nav.Link>
          </Nav.Item>
          {profile.experiences && profile.experiences.length > 0 && (
          <Nav.Item>
            <Nav.Link eventKey="experience">{t('experience')}</Nav.Link>
          </Nav.Item>
        )}
          {profile.education && profile.education.length > 0 && (
          <Nav.Item>
            <Nav.Link eventKey="education">{t('education')}</Nav.Link>
          </Nav.Item>
        )}
          {profile.language && profile.language.length > 0 && (
          <Nav.Item>
            <Nav.Link eventKey="language">{t('languages')}</Nav.Link>
          </Nav.Item>
        )}
        </Nav>
        {activeTab === 'about' && renderAbout()}
        {activeTab === 'summary' && (
          <>
            <h3>{t('summary')}</h3>
            <p>{profile.aboutMe}</p>
            {isAdmin && renderAdminInfo()}
          </>
        )}
        {activeTab === 'experience' && profile.experiences && profile.experiences.length > 0 && renderExperiences()}
        {activeTab === 'skills' && profile.technologies && profile.technologies.length > 0 && renderSkills()}
        {activeTab === 'education' && profile.education && profile.education.length > 0 && renderEducation()}
        {activeTab === 'language' && profile.language && profile.language.length > 0 && renderLanguages()}
      </Card.Body>
    </Card>
  );
};

export default ProfileDetail;