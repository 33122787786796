import CreateJobListing from "./jobs/CreateJobListing";

type TranslationType = {
  [key in LanguageCode]: {
    [key: string]: string;
  };
};

export type LanguageCode = 'en' | 'tr';

export const translations: TranslationType = {
  en: {
    about: "About",
    summary: "Summary",
    experience: "Experience",
    education: "Education",
    languages: "Languages",
    skills: "Skills",
    digitalSkill: "Digital Skills",
    company: "Company",
    title: "Title",
    duration: "Start - End Date",
    current: "Current",
    description: "Description",
    school: "School",
    department: "Department",
    language: "Language",
    level: "Level",
    skill: "Skill",
    yearsOfExperience: "Years of Experience",
    present: "Present",
    age: "Age",
    technologies: "Technologies",
    countryOfResidence: "Country of Residence",
    noTechnologiesListed: "No Tech listing",
    notSpecified: "",
    year: "Year",
    noTitle: "",
    name: "Name",
    surname: "Surname",
    phoneNumber: "Phone Number",
    logout: "Logout",
    birthDate: "Birth Date",
    expectedNetSalary: "Expected Net Salary",
    noticePeriod: "Notice Period",
    days: "Days",
    add_skill: "Add Skill",
    remove: "Remove",
    add_language: "Add Language",
    start_date: "Start Date",
    end_date: "End Date",
    add_education: "Add Education",
    work_experience: "Work Experience",
    add_experience: "Add Experience",
    update_profile: "Update Profile",
    update_header: "Update Profile",
    create_profile: "Create Profile",
    create_header: "Create Profile",
    jobListings: 'Job Opportunities',
    createJobListing: 'Create Job Offer',
    experiences: 'Experiences'
  },
  tr: {
    about: "Hakkında",
    summary: "Özet",
    experience: "Deneyim",
    education: "Eğitim",
    languages: "Diller",
    skills: "Yetenekler",
    company: "Şirket",
    title: "Ünvan",
    duration: "Başlangıç - Bitiş Tarihi",
    current: "Güncel",
    description: "Açıklama",
    school: "Okul",
    digitalSkill: "Dijital Yetenekler",
    department: "Bölüm",
    language: "Dil",
    level: "Seviye",
    skill: "Yetenek",
    yearsOfExperience: "Deneyim Yılı",
    present: "Devam Ediyor",
    age: "Yaş",
    technologies: "Teknolojiler",
    countryOfResidence: "Konum",
    noTechnologiesListed: "Teknoloji Bilgisi yok",
    notSpecified: "",
    year: "Yıl",
    noTitle: "",
    name: "İsim",
    surname: "Soyisim",
    phoneNumber: "Telefon Numarası",
    logout: "Çıkış",
    birthDate: "Doğum Tarihi",
    expectedNetSalary: "Maaş Beklentisi",
    noticePeriod: "İhbar Süresi",
    days: "Gün",
    add_skill: "Yetenek Ekle",
    remove: "Kaldır",
    add_language: "Dil Ekle",
    start_date: "Başlangıç Tarihi",
    end_date: "Bitiş Tarihi",
    add_education: "Eğitim Bilgisi Ekle",
    work_experience: "İş Deneyimi",
    add_experience: "İş Deneyimi Ekle",
    update_profile: "Güncelle",
    update_header: "Profili Güncelle",
    create_profile: "Profil Oluştur",
    create_header: "Profil Oluştur",
    jobListings: 'İş İmkanları',
    createJobListing: 'İş İlanı Oluştur',
    experiences: 'Tecrübeler'
  }
};