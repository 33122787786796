import React, { createContext, useContext, useState, useEffect } from 'react';
import { getUserProfile } from '../api/api';

interface AuthContextType {
  isLoggedIn: boolean;
  userProfile: any | null;
  isProfileCreated: boolean;
  setIsProfileCreated: (value: boolean) => void;
  login: (response: any) => void;
  logout: () => void;
  userId: number;
  userLevel: string;
  setUserId: any;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<any | null>(null);
  const [userLevel, setUserLevel] = useState('');
  const [userId, setUserId] = useState<number>(0);
  const [isProfileCreated, setIsProfileCreated] = useState<boolean>(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUserId = localStorage.getItem('userId');
    if (token && storedUserId) {
      setIsLoggedIn(true);
      setUserId(parseInt(storedUserId, 10));
      setUserLevel(localStorage.getItem('userLevel') || '');
      setIsProfileCreated(localStorage.getItem('isProfileCreated') === 'true');
      fetchUserProfile(parseInt(storedUserId, 10));
    }
  }, []);

  const fetchUserProfile = async (id: number) => {
    try {
      const response = await getUserProfile(id);
      setUserProfile(response.data);
      setIsProfileCreated(!!response.data);
      localStorage.setItem('isProfileCreated', (!!response.data).toString());
    } catch (error) {
      console.error('Failed to fetch user profile:', error);
    }
  };

  const login = (response: any) => {
    localStorage.setItem('token', response.accessToken);
    localStorage.setItem('userId', response.user.employeeId);
    localStorage.setItem('userLevel', response.user.role);
    localStorage.setItem('isProfileCreated', response.user.isProfileCreated.toString());
    
    setIsLoggedIn(true);
    setUserId(response.user.employeeId);
    setUserLevel(response.user.role);
    setIsProfileCreated(response.user.isProfileCreated);
    fetchUserProfile(response.user.employeeId);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userLevel');
    localStorage.removeItem('isProfileCreated');
    setIsLoggedIn(false);
    setUserProfile(null);
    setUserLevel('');
    setUserId(0);
    setIsProfileCreated(false);
  };

  return (
    <AuthContext.Provider value={{ 
      isLoggedIn, 
      userProfile, 
      login, 
      logout, 
      isProfileCreated, 
      setIsProfileCreated,
      userId, 
      setUserId,
      userLevel 
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
