import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer'; // Footer'ı import edin
import JobProfileDashboard from './components/JobProfile';
import ProfileForm from './components/ProfileForm';
import Login from './components/Login';
import { AuthProvider, useAuth } from './components/contexts/AuthContext';
import UpdateProfile from './components/UpdateProfile';
import Register from './components/Register';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminApprovalDashboard from './components/AdminApprovalDashboard';
import { LanguageProvider } from './components/contexts/LanguageContext';
import JobListingsDashboard from './components/jobs/JobListingsDashboard';
import CreateJobListing from './components/jobs/CreateJobListing';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <LanguageProvider>
        <Router>
          <AppContent />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Router>
      </LanguageProvider>
    </AuthProvider>
  );
};

const ProtectedAdminRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isLoggedIn, userLevel } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  if (userLevel !== 'ADMIN') {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

const AppContent: React.FC = () => {
  const { isLoggedIn, userProfile, logout, isProfileCreated, userLevel } = useAuth();
  return (
    <div className="d-flex flex-column min-vh-100"> {/* Flex container for entire page */}
      {(
        <Header 
          onLogout={logout}
          isAdmin={userLevel === 'ADMIN'}
        />
      )}
      <main className="flex-grow-1">
        <Routes>
          <Route path="/login" element={
            isLoggedIn ? <Navigate to="/" replace /> : <Login />
          } />
          <Route path="/" element={
            <JobProfileDashboard/>
          } />
          <Route path="/profile" element={
            isLoggedIn ? (
              isProfileCreated ? <UpdateProfile /> : <ProfileForm initialData={userProfile || {}} />
            ) : <Navigate to="/login" replace />
          } />
          <Route path="/register" element={
            isLoggedIn ? <Navigate to="/" replace /> : <Register />
          } />
          <Route path="/job-listings" element={
            <ProtectedAdminRoute>
              <JobListingsDashboard />
            </ProtectedAdminRoute>
          } />
          <Route path="/create-job-listing" element={
            <ProtectedAdminRoute>
              <CreateJobListing />
            </ProtectedAdminRoute>
          } />
          <Route path="/admin/approval" element={
            <ProtectedAdminRoute>
              <AdminApprovalDashboard />
            </ProtectedAdminRoute>
          } />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default App;