import React from 'react';
import { Card } from 'react-bootstrap';
import { useLanguage } from '../contexts/LanguageContext';
import { Job } from '../types';

const JobListingDetail: React.FC<Job> = (listing) => {
  const { t } = useLanguage();

  return (
    <Card>
      <Card.Body>
        <Card.Title>{listing.title}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{listing.company}</Card.Subtitle>
        <Card.Text>
          <strong>{t('location')}:</strong> {listing.location}<br />
          <strong>{t('description')}:</strong> {listing.description}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default JobListingDetail;