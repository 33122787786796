import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Accordion, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { deleteProfile, getUserProfiles, approveProfile, getJobs, deleteJob, updateJob } from './api/api';
import { Job, Profile } from './types';
import { LanguageCode, translations } from './translations';

const AdminApprovalDashboard: React.FC = () => {
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [loading, setLoading] = useState(true);
  const [language] = useState<LanguageCode>('en');
  const t = translations[language];

  useEffect(() => {
    fetchUnapprovedData();
  }, []);

  const fetchUnapprovedData = async () => {
    try {
      setLoading(true);
      const response = await getUserProfiles('false');
      const jobData = await getJobs('false');
      setProfiles(response.data);
      setJobs(jobData.data);
    } catch (error) {
      toast.error('Failed to fetch unapproved profiles & jobs');
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async (id: number) => {
    try {
      await approveProfile(id);
      toast.success('Profile approved successfully');
      fetchUnapprovedData();
    } catch (error) {
      toast.error('Failed to approve profile');
    }
  };

  const handleReject = async (id: number) => {
    try {
      await deleteProfile(id);
      toast.success('Profile rejected successfully');
      fetchUnapprovedData();
    } catch (error) {
      toast.error('Failed to reject profile');
    }
  };

  const handleApproveJob = async (id: number) => {
    try {
      const data = await updateJob(id, { isApproved: true });
      if (data.status === 200) {
        toast.success('Job approved successfully');
        fetchUnapprovedData();
      } else {
        toast.error('Error update job');
      }

    } catch (error) {
      toast.error('Failed to approve job');
    }
  };

  const handleRejectJob = async (id: number) => {
    try {
      await deleteJob(id);
      toast.success('Job rejected successfully');
      fetchUnapprovedData();
    } catch (error) {
      toast.error('Failed to reject job');
    }
  };


  const renderProfileDetails = (profile: Profile) => (
    <Card.Body>
      <h5>{t.summary}</h5>
      <p>{profile.aboutMe}</p>

      <h5>{t.experiences}</h5>
      {profile.experiences && profile.experiences.length > 0 ? (
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>{t.company}</th>
              <th>{t.title}</th>
              <th>{t.duration}</th>
            </tr>
          </thead>
          <tbody>
            {profile.experiences.map((exp, index) => (
              <tr key={index}>
                <td>{exp.company}</td>
                <td>{exp.title}</td>
                <td>{`${exp.startDate} - ${exp.endDate || t.present}`}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No experience data available</p>
      )}

      <h5>{t.education}</h5>
      {profile.education && profile.education.length > 0 ? (
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>{t.school}</th>
              <th>{t.department}</th>
              <th>{t.duration}</th>
            </tr>
          </thead>
          <tbody>
            {profile.education.map((edu, index) => (
              <tr key={index}>
                <td>{edu.name}</td>
                <td>{edu.department}</td>
                <td>{`${edu.startDate} - ${edu.endDate}`}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No education data available</p>
      )}

      <h5>{t.skills}</h5>
      {profile.technologies && profile.technologies.length > 0 ? (
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>{t.digitalSkill}</th>
            </tr>
          </thead>
          <tbody>
            {profile.technologies.map((skill, index) => (
              <tr key={index}>
                <td>{skill.name}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No skills data available</p>
      )}
    </Card.Body>
  );

  const renderJobDetails = (job: Job) => (
    <Card.Body>
      <h5>{t.jobDescription}</h5>
      <p>{job.description}</p>

      <Table striped bordered hover size="sm">
        <tbody>
          <tr>
            <th>{t.company}</th>
            <td>{job.company}</td>
          </tr>
          <tr>
            <th>{t.title}</th>
            <td>{job.title}</td>
          </tr>
          <tr>
            <th>{t.countryOfResidence}</th>
            <td>{job.location}</td>
          </tr>
        </tbody>
      </Table>
    </Card.Body>
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container fluid>
      <h1 className="my-4">Admin Approval Dashboard</h1>
      
      <h2 className="mt-5 mb-3">Profiles Waiting for Approval</h2>
      {profiles.length === 0 ? (
        <p>No profiles waiting for approval.</p>
      ) : (
        <Accordion>
          {profiles.map((profile, index) => (
            <Accordion.Item key={profile.id} eventKey={`profile-${index}`}>
              <Accordion.Header>
                <Row className="w-100 align-items-center">
                  <Col xs={12} md={4}>
                    <strong>{`${profile.name} ${profile.surname}`}</strong>
                  </Col>
                  <Col xs={12} md={4} className="text-muted">
                    {profile.title}
                  </Col>
                  <Col xs={12} md={4} className="text-end">
                    <Button 
                      variant="success" 
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleApprove(profile.id);
                      }}
                      className="me-2"
                    >
                      Approve
                    </Button>
                    <Button 
                      variant="danger" 
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleReject(profile.id);
                      }}
                    >
                      Reject
                    </Button>
                  </Col>
                </Row>
              </Accordion.Header>
              <Accordion.Body>
                {renderProfileDetails(profile)}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}

      <h2 className="mt-5 mb-3">Jobs Waiting for Approval</h2>
      {jobs.length === 0 ? (
        <p>No jobs waiting for approval.</p>
      ) : (
        <Accordion>
          {jobs.map((job, index) => (
            <Accordion.Item key={job.id} eventKey={`job-${index}`}>
              <Accordion.Header>
                <Row className="w-100 align-items-center">
                  <Col xs={12} md={4}>
                    <strong>{job.title}</strong>
                  </Col>
                  <Col xs={12} md={4} className="text-muted">
                    {job.company}
                  </Col>
                  <Col xs={12} md={4} className="text-end">
                    <Button 
                      variant="success" 
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleApproveJob(job.id);
                      }}
                      className="me-2"
                    >
                      Approve
                    </Button>
                    <Button 
                      variant="danger" 
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRejectJob(job.id);
                      }}
                    >
                      Reject
                    </Button>
                  </Col>
                </Row>
              </Accordion.Header>
              <Accordion.Body>
                {renderJobDetails(job)}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
    </Container>
  );
};

export default AdminApprovalDashboard;