import React from 'react';
import { Card } from 'react-bootstrap';
import { Profile } from './types';
import { LanguageCode } from './translations';
import { useLanguage } from './contexts/LanguageContext';

interface ProfileCardProps {
  profile: Profile;
  onClick: (profile: Profile) => void;
  language: LanguageCode;
}

const ProfileCard: React.FC<ProfileCardProps> = ({ profile, onClick, language }) => {
  const { t } = useLanguage();
  const techString = profile.technologies && profile.technologies.length > 0
  ? profile.technologies.map(item => item.name).join(', ')
  : t('noTechnologiesListed'); 
  return (
    <Card 
      className="mb-3 profile-card" 
      onClick={() => onClick(profile)}
      style={{ 
        transition: 'all 0.3s ease-in-out',
        cursor: 'pointer'
      }}
    >
      <Card.Body>
        <Card.Title>{profile.title || t('noTitle')}</Card.Title>
        <Card.Text>
          {t('experience')}: {profile.experience || 0} {t('year')}<br />
          {t('technologies')}: {techString}<br />
          {t('countryOfResidence')}: {profile.location || t('notSpecified')}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ProfileCard;